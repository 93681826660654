<template>
  <div>
    <v-data-table
      :headers="headersTotalUser"
      :items="$store.state.homes.userTotalByChips"
      :sort-by="['chipAmount']"
      :sort-desc="[true]"
      :items-per-page="10000"
      hide-default-footer
      style="max-width:1000px; margin: auto;"
      class="elevation-1"
    >
      <template v-slot:item.userName="{ item }">
        <user-detail-card
          :userId="item.id"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
          :level="item.level"
          :flag="item.flag"
          :isOnline="item.playerOnline.o"
        />
      </template>

      <template v-slot:item.vipInfo.p="{ item }">
        <div>
          {{ item.vipInfo.p | formatMoney }}
        </div>
      </template>
      <template v-slot:item.vipInfo.l="{ item }">
        <v-img
          v-if="item.vipInfo.l"
          :src="
            `https://pokerface.restplay.com/Common/vip${item.vipInfo.l}.png`
          "
          max-width="50"
        />
      </template>
      <template v-slot:item.chip="{ item }">
        <div>
          {{ item.chip | formatMoney }}
        </div>
      </template>
      <template v-slot:item.playerOnline.l="{ item }">
        <div v-if="!item.playerOnline.o">
          <timeago
            :style="
              ` color: ${item.leftTime < 86400 * 3.1 ? 'orange' : '#ff7e7e'}`
            "
            locale="zh_TW"
            :datetime="new Date(item.playerOnline.l * 1000)"
          ></timeago>
        </div>
      </template>
      <template v-slot:item.playerOnline.o="{ item }">
        <div
          :style="
            ` height: 10px;  width: 10px; border-radius:10px;background: ${
              item.playerOnline.o ? 'lime' : '#ff7e7e'
            }`
          "
        ></div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import UserDetailCard from "../../components/Users/UserDetailCard.vue";

export default {
  components: { UserDetailCard },
  data() {
    return {
      headersTotalUser: [
        { text: "UserName", value: "userName" },
        { text: "Chip", value: "chip" },
        { text: "Gold Chip", value: "goldAmount" },
        { text: "VipLevel", value: "vipInfo.l" },
        { text: "Vip CurrentPoint", value: "vipInfo.p" },
        { text: "Is Online", value: "playerOnline.o" },
        { text: "Last Online", value: "playerOnline.l" },
      ],
    };
  },
  async mounted() {
    this.$store.dispatch("homes/getUserTotalByChip");
  },
};
</script>
